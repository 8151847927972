import React from "react";
import landingPageBackground from "./assets/images/landingpage.jpg";
import "./assets/styles/App.css";

function App() {
  return (
    <body>
      <div
        className="landing-background"
        style={{
          backgroundImage: `url(${landingPageBackground})`,
          backgroundSize: "contain",
        }}
      />
      <div
        style={{
          minWidth: "100vw",
          minHeight: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <h1 style={{ fontSize: "5rem" }}>Radosnica</h1>
        <h1 style={{ fontSize: "3.5rem" }}>U PRIPREMI!!!</h1>
      </div>
    </body>
  );
}

export default App;
